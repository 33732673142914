<template>
  <div class="personal-container">
    <list ref="list" :searchUrl="searchUrl" :searchParams.sync="searchParams" :headers="headers" >
      <template #searchSlot>
        <v-input v-model="searchParams.userName" label="用户" placeholder="请输入用户"></v-input>
        <v-input v-model="searchParams.mobileNum" label="手机号" placeholder="请输入手机号"></v-input>
        <!-- <v-select v-model="searchParams.classify" label="积分种类" :options="integraOptions"></v-select> -->
        <v-select2 label="运营主体"
                   v-model="searchParams.regionId"
                   v-bind="regionParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="详情" type="text" @click="view(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { getPersonalIntegralListByPage } from './api'
import { integraOptions, platformOptions } from './map'
import PersonalIntegraDetail from './PersonalIntegraDetail.vue'
import { regionParams } from 'common/select2Params'
export default {
  name: 'PersonalIntegraLists',
  components: {
    PersonalIntegraDetail
  },
  data () {
    return {
      regionParams,
      searchUrl: getPersonalIntegralListByPage,
      maxDate: new Date(),
      userInfo: {
        userName: '',
        phone: '',
        regionId: ''
      },
      searchParams: {
        // classify: undefined, // 积分种类
        // platform: '', // 发放方
        userName: '', // 接收人
        mobileNum: '', // 手机号码
        // userIdGroup: true
      },
      headers: [  
        {
          prop: 'userName',
          label: '用户'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'sourceType',
          label: '积分种类',
          formatter: () => {
            return '租户积分'
          }
        },
        {
          prop: 'regionName',
          label: '运营主体'
        },
        {
          prop: 'intNum',
          label: '积分总数'
        },
        {
          prop: 'fixedIntegral',
          label: '当前可用积分'
        },
        {
          prop: 'stayOutIntegral',
          label: '待发放积分'
        }
      ],
      integraOptions: integraOptions,
      platformOptions: platformOptions
    }
  },
  methods: {
    view (row) {
      this.userInfo.userName = row.userName
      this.userInfo.phone = row.mobileNum
      this.userInfo.regionName = row.regionName
      this.userInfo.sourceType = '租户积分'
      this.$router.push({
        path: 'personalIntegraDetail',
        query: {
          userInfo: JSON.stringify(this.userInfo),
          id: row.userId,
          regionId: row.regionId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-container {
  overflow: scroll;
}
</style>
